export const getTopDestinationsLocaleString = (iso_code) => {
  if (iso_code) {
    if (iso_code === 'PA') {
      return 'home.topDestinationsPA'
    }
    if (iso_code === 'CO') {
      return 'home.topDestinationsCO'
    }
    if (iso_code === 'DOM') {
      return 'home.topDestinationsDOM'
    }
  } else {
    return 'home.topDestinations'
  }
}

export const getCountryLocaleString = (iso_code) => {
  if (iso_code) {
    if (iso_code === 'PA') {
      return 'common.countryPA'
    }
    if (iso_code === 'CO') {
      return 'common.countryCO'
    }
    if (iso_code === 'DOM') {
      return 'common.countryDOM'
    }
    if (iso_code === 'GTM') {
      return 'common.countryGTM'
    }
  } else {
    return 'common.countryPA'
  }
}

