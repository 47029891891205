import { get } from '@/services/axios/axios'
import { googleTrack } from '@/utils/analytics'
import {
  setExperienceDetailAction,
  setLoadingExperienceDetailAction,
  setNotFoundExperienceAction,
} from 'store/actions'
import { USD } from '@/constants/common'
import { GotuuriState } from '@/models/gotuuri-state'
import { ActionType } from '@/store/actions/base-action'

export const useGetExperienceDetail = (
  dispatch: (action: ActionType) => void,
  state: GotuuriState
) => {
  const getExperienceDetail = async (id) => {
    dispatch(setExperienceDetailAction(null))
    await get({
      path: `experience/${id}`,
      headers: { 'user-id': state?.user?.id },

    }).then((data: any) => {
      const remoteConfig = window.localStorage.getItem('remoteConfig')
      googleTrack('ExperienceView', {
        ecommerce: {
          value: data?.is_package ? data?.price_package : data?.price_adults,
          currency: USD,
          referent: state?.user?.referent,
          experiences: {
            experienceId: data?.id,
            experienceName: data?.name,
            destinationID: data?.city?.id,
            destinationName: data?.city?.name,
            tuuriId: data?.companies[0]?.id,
            tuuriName: data?.companies[0]?.company_name,
            language: state.locale,
            loggedIn: state.isLoggedIn,
            price: data?.price_adults,
          },
        },
      })

      data.included = []
      data.included = [
        ...(data.what_includes || []).map((include) => ({
          title: include.name,
          detail: include.details,
          include: true,
          iconUrl: include.icon_url,
          isNewInclude: true,
        })),
      ].filter(({ include }) => !!include)

      const detailsItems = [
        {
          key: 'is_private',
        },
        {
          key: 'schedule',
        },
        {
          key: 'duration',
        },
        {
          key: 'lang_code',
        },
        {
          key: 'difficulty_level',
        },
        {
          key: 'min_persons',
        },
        {
          key: 'max_persons',
        },
        {
          key: 'meeting_reference',
        },
        {
          key: 'min_age_allowed',
        }
      ]
      const supportLanguages = [
        {
          key: 'is_spanish',
        },
        {
          key: 'is_english',
        },
        {
          key: 'is_german',
        },
        {
          key: 'is_french',
        },
      ]

      data.details = detailsItems
        .map(({ key }) => {
          return {
            title: key,
            value: data[key],
          }
        })
        .filter(({ value }) => value !== null)

      data.details[3].value = supportLanguages
        .map(({ key }) => (data[key] ? key : null))
        .filter((language) => language !== null)

      data.include_babies = data.price_babies !== null || data.include_babies
      data.include_children =
      data.price_children !== null || data.include_children


      dispatch(setNotFoundExperienceAction(false))
      dispatch(setExperienceDetailAction(data))
      dispatch(setLoadingExperienceDetailAction(false))
      
    }).catch((error) => {
     
      dispatch(setNotFoundExperienceAction(true))
      dispatch(setLoadingExperienceDetailAction(false))
    })
  }

  return { getExperienceDetail }
}
