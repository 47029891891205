import { amountFormatter } from '@/utils/amountFormatter'
import { track } from '@/utils/analytics'
import { normalizeUrl, normalizeItem } from '@/utils/normalizeUrl'
import { userPreferredLanguage } from '@/utils/userPreferredLanguage'
import { Image } from '@nextui-org/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import fullHeartIcon from 'assets/svgs/fullHeartIcon.svg'
import emptyHeartIcon from 'assets/svgs/emptyHeartIcon.svg'
import { SignInUp } from '../SignInUp'
import { normalizeAltExperienceImg } from '@/utils/normalizeAltImg'
import { getCountryLocaleString } from '@/utils/generalTitle.Util'
import { ExperienceType } from '@/constants/generalCarousel'
import { CleverEvents } from '@/utils/events'

interface ExperienceListProps {
  experiences: any
  title?: string
  user: any
  setFavoriteExperience: ({ experience, userId, type }) => void
}
const ExperienceList = ({
  experiences,
  title,
  user,
  setFavoriteExperience,
}: ExperienceListProps) => {
  const { t } = useTranslation()
  const location = useLocation().pathname.split('/')[1]
  const locations = {
    destinations: 'Destination',
    categories: 'Category',
    highlights: 'Highlights',
    favorites: 'Favorites',
    search: 'Search',
    country: 'Country',
  }

  const defaultLang = userPreferredLanguage()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  const addFavorite = (e, experience) => {
    e.preventDefault()
    e.stopPropagation()
    !!user
      ? setFavoriteExperience({
          experience: experience,
          userId: user?.id,
          type: 'experiences',
        })
      : setIsOpen(true)
  }

  const handleCardClick = (route) => {
    window.open(route, '_blank')
  }

  return (
    <>
      {!!title ? (
        <div>
          <h2 className="text-2xl md:text-4xl mb-6 mt-12">{title}</h2>
        </div>
      ) : null}
      <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-3 md:gap-x-4 lg:gap-x-5 gap-y-8">
        {!!experiences
          ? experiences.map((experience, index) => {
              return (
                <div
                  key={index}
                  className=" h-72 lg:h-[435px] overflow-hidden shadow-md rounded-b-2xl  grid grid-rows-[auto,1fr] cursor-pointer"
                  role="link"
                  onClick={() => {
                    handleCardClick(normalizeUrl(experience, defaultLang))
                    track('Experience View', {
                      experienceName: experience?.name,
                      experienceId: experience?.id,
                      screen: locations[location] || 'Home',
                    })
                  }}
                >
                  <div className="relative max-w-full h-40 lg:h-64 overflow-hidden rounded-t-2xl">
                    <Image
                      removeWrapper
                      isZoomed
                      className="object-cover w-full h-full rounded-none"
                      alt={normalizeAltExperienceImg(experience)}
                      src={experience.photos_url[0]}
                    />
                    <img
                      src={
                        !!!experience.favorite ? emptyHeartIcon : fullHeartIcon
                      }
                      role="none"
                      className="absolute right-6 top-6 w-7 h-6 !bg-transparent z-10 hover:scale-110 active:scale-125"
                      onClick={(e) => addFavorite(e, experience)}
                      alt="heart icon svg"
                    />
                  </div>
                  <div className="p-3 flex flex-col">
                    <div>
                      <h3 className=" text-base md:text-lg lg:text-xl text-black leading-5 md:leading-6 line-clamp-2">
                        {experience.name}
                      </h3>
                    </div>
                    <div>
                      {experience?.country ? (
                        <div
                          className="text-xs lg:text-base text-gotuuriCyan w-fit"
                          onClick={(e) => {
                            window.scroll(0, 0)
                            e.stopPropagation()
                            CleverEvents.CountryView({
                              name: experience.country?.name,
                              id: experience.country?.id,
                              screen: 'Experience',
                            })
                            navigate(
                              normalizeItem({
                                ...experience.country,
                                type: ExperienceType.COUNTRY,
                              })
                            )
                          }}
                          role="link"
                        >
                          {t(
                            getCountryLocaleString(experience.country?.iso_code)
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="flex justify-between items-end ">
                        <div className="flex flex-col w-full">
                          <p className=" text-xs text-darkGray mt-px">
                            {experience.price_adults
                              ? t('experienceList.priceStart')
                              : t('experienceList.pricePerGroup')}
                          </p>
                          <div className="flex justify-between content-center items-center">
                            <h3 className="text-xl md:text-3xl leading-6">
                              {amountFormatter(
                                experience.price_adults ??
                                  experience.price_package
                              )}
                            </h3>

                            {experience?.rating ? (
                              <div className=" rounded-3xl h-6 bg-gray-200 flex justify-center items-center px-2">
                                <p className="mr-px">★</p>
                                <p className="text-center text-xs">
                                  {experience.rating.toFixed(1)}
                                </p>
                              </div>
                            ) : null}
                          </div>

                          {experience?.original_price > 0 &&
                            experience?.show_original_price && (
                              <h3 className="text-xs md:text-lg text-[#A6A6A6] line-through">
                                {amountFormatter(experience.original_price)}
                              </h3>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          : null}
      </div>
      {isOpen && <SignInUp open={isOpen} onClose={() => setIsOpen(false)} />}
    </>
  )
}
export default ExperienceList
