import { Layout } from 'components/Layout'
import { useTranslation } from 'react-i18next'
import { SearchBar } from '../SearchBar'
import { useNavigate } from 'react-router-dom'

interface HeaderBannerProps {}
const HeaderBanner = ({}: HeaderBannerProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const VITE_3DS_ENV = import.meta.env.VITE_3DS_ENV
  const MAIN_BANNER = `https://firebasestorage.googleapis.com/v0/b/winter-rider-365419.appspot.com/o/${VITE_3DS_ENV != 'production' ? 'development%2F' : 'production%2F'}data_web%2Fimages%2Fmain_banner.webp?alt=media`

  const SearchBarCallBack = ({ filterUrl }) => {
    navigate(`/search?${filterUrl}`)
  }
  return (
    <div>
      <div
        className="hidden md:flex bg-cover bg-center h-102 text-white object-fill flex-col -mt-20 justify-center items-center relative"
        style={{ backgroundImage: `url(${MAIN_BANNER})` }}
      >
        <div className="gradient-overlay"></div>
        <div className="flex w-4/6 justify-center items-center bottom-0 relative z-10">
          <Layout>
            <div className='justify-center text-center'>
              <h1 className="text-5xl md:text-[54px] text-white text-left items-center my-8">
                {t('header.title')}
              </h1>
            </div>
            <SearchBar
              showSubmitButton
              callBack={SearchBarCallBack}
              eventScreen="Home"
            />
          </Layout>
        </div>
      </div>
      <div className="block md:hidden mt-8">
        <Layout>
          <SearchBar
            showFilterIcon
            callBack={SearchBarCallBack}
            eventScreen="Home"
          />
        </Layout>
      </div>
    </div>
  )
}

export default HeaderBanner
