import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import {
  CustomLeftArrow,
  CustomRightArrow,
  Responsive,
} from '../CarouseElements'
import 'react-photo-view/dist/react-photo-view.css'
import { Loading } from '@/components/Loading'
import { userPreferredLanguage } from '@/utils/userPreferredLanguage'
import { useLocation, useNavigate } from 'react-router-dom'
import { track } from '@/utils/analytics'
import { Image } from '@nextui-org/react'
import { useEffect, useState } from 'react'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { normalizeItem, normalizeUrl } from '@/utils/normalizeUrl'
import { normalizeAltExperienceImg } from '@/utils/normalizeAltImg'
import fullHeartIcon from 'assets/svgs/fullHeartIcon.svg'
import emptyHeartIcon from 'assets/svgs/emptyHeartIcon.svg'
import { ExperienceType } from '@/constants/generalCarousel'
import { amountFormatter } from '@/utils/amountFormatter'
import { useTranslation } from 'react-i18next'
import { getCountryLocaleString } from '@/utils/generalTitle.Util'
import { SignInUp } from '@/components/SignInUp'
import { useIsMobile } from '@/utils/mobile'

const ExperienceCarousel = ({
  textClass,
  eventScreen,
}: {
  textClass?: string
  eventScreen?: string
}) => {
  const {
    hooks: { setFavoriteExperience },
    state: { user, carousels, loadingCarousels },
  } = useGotuuriContext()

  const { t } = useTranslation()

  const defaultLang = userPreferredLanguage()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const isMobile = useIsMobile()

  const handleCardClick = (route) => {
    window.open(route, '_blank')
  }

  if (loadingCarousels) {
    return (
      <div className={'h-72 lg:h-[415px]'}>
        <Loading fullHeight={false} />
      </div>
    )
  }

  if (!!!carousels) {
    return null
  }

  return carousels.map((carousel, index) => {
    return (
      <div key={index}>
        <h2 className={`text-xl md:text-3xl mb-6 mt-12 ${textClass}`}>
          {carousel.title}
        </h2>
        <Carousel
          className="static -mr-[11px] md:-mr-[9px] md:ml-1"
          responsive={{
            superLargeDesktop: {
              breakpoint: { max: 4000, min: 1540 },
              items: 4,
            },
            desktop: {
              breakpoint: { max: 1540, min: 1280 },
              items: 4,
            },
            tablet: {
              breakpoint: { max: 1280, min: 768 },
              items: 3,
            },
            mobile: {
              breakpoint: { max: 768, min: 0 },
              items: 2,
            },
          }}
          infinite={true}
          ssr={true}
          focusOnSelect={false}
          removeArrowOnDeviceType={['mobile', 'tablet']}
          autoPlay={true}
          autoPlaySpeed={isMobile ? 3000 : 2000}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
        >
          {!!carousel?.experiences &&
            carousel?.experiences.map((experience, index) => {
              return (
                <div
                  className="my-1 pr-2.5 md:pr-5 pl-0.5 2xl:pl-px"
                  key={index}
                >
                  <div
                    className=" h-72 lg:h-[435px] overflow-hidden shadow-md rounded-b-2xl  grid grid-rows-[auto,1fr] cursor-pointer"
                    role="link"
                    onClick={() => {
                      handleCardClick(normalizeUrl(experience, defaultLang))
                      track('Experience View', {
                        experienceName: experience?.name,
                        experienceId: experience?.id,
                        screen: `Carousel-${carousel.title}`,
                      })
                    }}
                  >
                    <div className="relative max-w-full h-40 lg:h-64 overflow-hidden rounded-t-2xl">
                      <Image
                        removeWrapper
                        isZoomed
                        className="object-cover w-full h-full rounded-none"
                        alt={normalizeAltExperienceImg(experience)}
                        src={experience.photos_url[0]}
                        loading="lazy"
                      />
                      <img
                        src={
                          !!!experience.favorite
                            ? emptyHeartIcon
                            : fullHeartIcon
                        }
                        alt="heart icon svg"
                        role="none"
                        className="absolute right-6 top-6 w-7 h-6 !bg-transparent z-10 hover:scale-110 active:scale-125"
                        onClick={(e) => {
                          e.stopPropagation()
                          !!user
                            ? setFavoriteExperience({
                                experience: experience,
                                userId: user?.id,
                                type: 'carousel',
                              })
                            : setIsOpen(true)
                        }}
                      />
                    </div>
                    <div className="p-3 flex flex-col">
                      <div>
                        <h3 className=" text-base md:text-lg lg:text-xl text-black leading-5 md:leading-6 line-clamp-2">
                          {experience.name}
                        </h3>
                      </div>
                      <div>
                        {experience?.country ? (
                          <div
                            className="text-xs lg:text-base text-gotuuriCyan"
                            onClick={(e) => {
                              window.scroll(0, 0)
                              e.stopPropagation()
                              navigate(
                                normalizeItem({
                                  ...experience.country,
                                  type: ExperienceType.COUNTRY,
                                })
                              )
                            }}
                            role="link"
                          >
                            {t(
                              getCountryLocaleString(
                                experience.country?.iso_code
                              )
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="flex justify-between items-end ">
                          <div className="flex flex-col w-full">
                            <p className=" text-xs text-darkGray mt-px">
                              {experience.price_adults
                                ? t('experienceList.priceStart')
                                : t('experienceList.pricePerGroup')}
                            </p>
                            <div className="flex justify-between content-center items-center">
                              <h3 className="text-xl md:text-3xl leading-6">
                                {amountFormatter(
                                  experience.price_adults ??
                                    experience.price_package
                                )}
                              </h3>

                              {experience?.rating ? (
                                <div className=" rounded-3xl h-6 bg-gray-200 flex justify-center items-center px-2">
                                  <p className="mr-px">★</p>
                                  <p className="text-center text-xs">
                                    {experience.rating.toFixed(1)}
                                  </p>
                                </div>
                              ) : null}
                            </div>

                            {experience?.original_price > 0 &&
                              experience?.show_original_price && (
                                <h3 className="text-xs md:text-lg text-[#A6A6A6] line-through">
                                  {amountFormatter(experience.original_price)}
                                </h3>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </Carousel>
        {isOpen && <SignInUp open={isOpen} onClose={() => setIsOpen(false)} />}
      </div>
    )
  })
}

export default ExperienceCarousel
